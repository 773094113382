import * as React from 'react';
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
// import styled from "@emotion/styled"
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import SiteWidthWrapper from '../../components/wrappers/siteWidthWrapper';
import HeroImageA from '../../components/MainHero';
import CenteredButton from '../../components/centeredButton';

const PressureWashingServices = () => (
  <Layout>
		<Seo title='Top1 Pressure Washing'
         description='Professional pressure washing services' />

      {/* Hero Image is found in /components/MainHero.js */}
      <HeroImageA />

		<SiteWidthWrapper>
			<h1>Pressure Washing</h1>
			<p>For an exterior paint job to be successful, a thorough pressure washing is necessary. The over-growth of moss, mildew and accumulating dirt prevent the paint to adhere to a surface properly causing paint failure and premature flaking.</p>
			<p>A good pressure wash can help prolong the life of other areas around your property or business, For example, your driveway, sidewalks, and stone features will stay looking new for much longer when they get cleaned regularly. Here at Top1 Painting we have all the tools, equipment, and experienced to pressure wash all types of surfaces including areas hard to reach and will get the job done fast, safely and hassle-free.</p>
			<CenteredButton>Call Now</CenteredButton>
		</SiteWidthWrapper>
	</Layout>
);

export default PressureWashingServices;
